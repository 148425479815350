import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResetEmail } from './reset.state';

export const RESET_STATE_NAME = 'reset';
const selectRestState = createFeatureSelector<ResetEmail>(RESET_STATE_NAME);
const selectResetAccount = createSelector(selectRestState, state => {
  return state.resetMailSent;
});

export default { selectResetAccount };
